<template>
  <div class="contact">
    <div class="neck_banner">
      <img class="neck_background" src="../assets/contact/first.png"/>
      <div class="neck_text_eng"><span>CONTACT US</span></div>
      <div class="neck_text_chinese">
        <div class="neck_text_icon_image">
          <img class="icon_image" src="../assets/service/contact_icon.png" />
          <span>联系我们</span>
        </div>
        <div class="neck_text_description_chinese">如果您对富胜家居的解决方案、产品、技术有任何问题，请通过这些渠道与我们联系</div>
      </div>
    </div>

    <!-- 销售区域 -->
    <div class="contact-body" id="area">
      <div class="contact-box">
        <div class="chest">
          <div class="chest_text1">全国销售区域</div>
          <div class="chest_text2">富胜家居全国办事处辐射区域</div>
          <div class="area">
            <!-- <div :style="{ 'height':scrollerHeight, 'width': scrollerWeight,'border': '1px dashed','margin-left':'8px' }" -->
            <div class="area-map-container">
              <div class="area-map" ref="charts"></div>
            </div>
            <div class="area-list">
              <div class="area-list-inner">
                <div v-for="(item,index) in areaList" :key="index" class="area-list-item fs-flex" @click="chooseArea(item)"
                  :class="index==areaIndex&&'light'">
                  <div style="margin-right: 30px">
                    <div>{{index+1}}</div>
                  </div>
                  <div @click="areaIndex=index">
                    <p class="item-active">{{item.areaName}}</p>
                    <p class="subtitle">{{item.address}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 展厅 -->
      <div class="contact-box" id="hall">
        <div class="chest">
          <div class="chest_text1">全国展厅</div>
          <div class="chest_text2">欢迎莅临实景展厅参观体验</div>
          <div class="hall" :class="[(screenWidth < 850 && 'fs-flex-column') || '']">
            <div class="hall-map">
              <a target="_blank" :href="exhibitionHall[currentHall]?.mapLink">
                <img style="width:100%;object-fit: cover;height: 100%;background-size: cover;"
                  :src="`/api/ffs/file/${exhibitionHall[currentHall]?.map?.id}`" /></a>
            </div>
            
            <div class="address">
              <div class="address-inner">
                <div v-for="(item,index) in exhibitionHall" :key="index" class="hall-list-item fs-flex"
                  @click="currentHall=index" :class="showIndex==index&&'light'">
                  <div style="margin-right: 30px">
                    <div>
                      <p>{{index+1}}</p>
                    </div>
                  </div>
                  <div @click="showIndex=index">
                    <p>{{item.name}}</p>
                    <p class="subsmalltitle">地址:{{item.address}}</p>
                    <template v-if="item.tel && item.tel.trim().length > 0">
                      <p class="subsmalltitle">电话:{{item.tel}}</p>
                    </template>
                    <template v-if="item.fax && item.fax.trim().length > 0">
                      <p class="subsmalltitle">传真:{{item.fax}}</p>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 联系工程师 -->
      <div class="contact-stripe" id="enginer">
        
        <div class="belly fs-flex fs-flex-between" :class="[(screenWidth < 850 && 'fs-flex-column') || '']">
          <div class="belly-left-stripe">
            <div class="belly-left">
              <div class="belly-left-text-box">
                <div class="left_text1">联系我们的</div>
                <div class="left_text1">销售工程师</div>
                <div class="left_text2">
                  想了解更多产品信息或进一步合作 请联系负责您所在区域的销售工程师
                </div>
              </div>
              <div class="belly-img">
                <img src="../assets/contact/boss.png" alt="" />
              </div>
            </div>
          </div>
          <div class="belly-right-stripe">
            <div class="belly-right">
              <div>
                <!-- <City @onChange="handCity" class="contact-city" /> -->
                <CityTwo  @onChange="handCity" class="contact-city"></CityTwo>
              </div>
              <div class="belly-right-engineer-out">
                <template v-if="engineerCity">
                  <div class="belly-right-engineer" v-for="(item,index) in salesContacts" :key="index">
                    <!-- <div class="belly-right1">工程师</div> -->
                    
                    <div class="belly-right2">联系人：{{ item.contact }}</div>
                    <div class="belly-right2">电话: {{ item.tel }}</div>
                    <div class="belly-right2">地址: {{ item.address }}</div>
                  </div>
                </template>
                <!-- <template v-else-if="salesContacts && salesContacts.length > 0">
                  初始情况下，只显示首条
                  <div class="belly-right-engineer">
                    <div class="belly-right1">工程师</div>
                    <div class="belly-right2">地址: {{ salesContacts[0].address }}</div>
                    <div class="belly-right2">联系人：{{ salesContacts[0].contact }}</div>
                    <div class="belly-right2">电话: {{ salesContacts[0].tel }}</div>
                  </div>
                </template> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 联系经销商 -->
      <div class="contact-stripe sales-contact">
        <div class="sales-contact-header-backgroud">
        </div>
        <div class="contact-box" id="sales">
          <div class="sales-contact-header-text">
            <div class="sales-contact-title">经销商查询</div>
            <div class="sales-contact-desc">
              富胜家居在各区域设立经销商，为经销商提供品牌授权，以便为您提供本地化服务，如有需要可联系您所在区域经销商
            </div>
          </div>
          <div class="sales-contact-panel" style="box-shadow: 0px 6px 46px 0px rgb(0 0 0 / 14%) !important;">
            <City @onChange="handDealer"  class="contact-city" />
            <div class="sales-info-list">
              <div class="sales-info-box" v-for="(item,index) in dealerContactsList" :key="index" :span="12">
                <Dealer :data="item" />
              </div>
              <!-- <el-row >
                <el-col v-for="(item,index) in dealerContactsList" :key="index" :span="12">
                  <Dealer :data="item" />
                </el-col>
              </el-row> -->
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as echarts from "echarts";
import shengfen from "@/assets/china.json"; //地图包
// import AMapLoader from "@amap/amap-jsapi-loader";
import City from "@/components/City";
import CityTwo from "@/components/City2";
import Dealer from "@/components/Dealer";


import request from "@/utils/request";
import { inject } from "vue";

// import AMapLoader from '@alife/amap-jsapi-loader';
//9014d4a0c4545d305ac46f8e248fca6a
export default {

  setup() {
    // 指示当前页的名称，以便使菜单显示高亮；
    const pageIndicator = inject("pageIndicator");
    pageIndicator.currentPageName = "contact";
    pageIndicator.lightMode = false; // 导航栏背景适应浅色内容模式；
    pageIndicator.headerGradient = false; // 导航栏背景渐变；
    pageIndicator.headerTransparent = false; // 导航栏背景全透明；
    
    console.log("inject pageIndicator[contact pdf books] ...", pageIndicator);
  },

  data() {
    return {
      engineerCity: undefined,

      provinceValue: "",
      cityValue: "",
      options1: [
        {
          value: "1",
          label: "四川省",
        },
        {
          value: "2",
          label: "北京市",
        },
      ],
      options2: [
        {
          value: "1",
          label: "成都市",
        },
        {
          value: "2",
          label: "德州市",
        },
      ],
      infoDemo: {
        address: "成都武侯区二环路西一段8号月光流域1-1-1705",
        contacts: "杜经理",
        phone: "+86 18980068005",
      },
      charts: null,

      current: {
        page: 0,
        size: 10,
      },
      salesContactsCityId: "",
      salesContactsProvinceId: "",
      salesContactsInfo: {}, //销售区域信息

      dealerContactsCityId: "",
      dealerContactsProvinceId: "",
      dealerContactsInfo: [], //经销商信息

      areaList: [],
      regions: [
        //  {
        //     name: "广东",
        //     itemStyle: {
        //       areaColor: "#00A0E9",
        //     },
        //   },
      ],
      exhibitionHall: [],
      currentHall: 0,
      currentPro: 0,
      salesContacts: [],
      dealerContactsList: [],
      areaIndex: -1,
      showIndex: -1,
    };
  },
  components: {
    City, Dealer,CityTwo
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["screenWidth"]),
    scrollerHeight: function () {
      return "833px"; //自定义高度
    },
    scrollerWeight: function () {
      return "100%"; //自定义宽度
    },
  },
  watch: {
    screenWidth: function () {
      setTimeout(() => {
        this.charts?.resize(); // 自适应大小变化
      }, 0);
    },
  },
  // created() {
  //   this.$nextTick(() => {
  //     this.initCharts();
  //     //this.initMap();
  //   });
  // },
  methods: {
    // 选择销售区域
    chooseArea(item) {
      this.regions = item.cities.map(itemC => {
        return {
          name: itemC.cityName,
          itemStyle: {
            areaColor: "#00A0E9",
          },
        }
      })
      this.initCharts();
    },
    // 销售工程师联系
    querySalesContactsList(province, city) {
      request({
        url: "/api/ui/salesContacts/list",
        method: "get",
        params: {
          province, city
        },
      })
        .then((res) => {
          if (res.code == 0) {
            console.log(res);
            this.salesContacts = res.data;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // 经销商联系方式
    queryDealerContactsList(province, city) {
      request({
        url: "/api/ui/dealerContacts/list",
        method: "get",
        params: {
          province, city
        },
      })
        .then((res) => {
          if (res.code == 0) {
            this.dealerContactsList = res.data;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getData() {
      // 获取全国销售区域
      request({
        url: "/api/ui/area/list",
        method: "get",
      })
        .then((res) => {
          if (res.code == 0) {
            this.areaList = res.data;
          }
          this.initCharts();
        })
        .catch((e) => {
          console.log(e);
        });
      // 获取全国展厅
      request({
        url: "/api/ui/exhibitionHall/list",
        method: "get",
      })
        .then((res) => {
          if (res.code == 0) {
            this.exhibitionHall = res.data;
          }
        })
        .catch((e) => {
          console.log(e);
        });

      // 获取全国展厅-右侧分页查询
      // request({
      //   url: "/api/ui/exhibitionHall/page",
      //   method: "get",
      //   params: {
      //     page: this.current.page,
      //     size: this.current.size,
      //   },
      // })
      // .then((res) => {
      //   if (res.code == 0) {
      //     console.log(res);
      //   }
      // })
      // .catch((e) => {
      //   console.log(e);
      // });
    },

    initCharts() {
      this.charts = echarts.init(this.$refs["charts"]);

      const option = {
        // grid:{ left: '0%', top: '0%',},
        // 地图配置
        geo: {
          map: "china",
          regions: this.regions,
          // regions: [
          //   {
          //     name: "广东",
          //     itemStyle: {
          //       areaColor: "#00A0E9",
          //     },
          //   },
          // ],
          // left:'0%',
          // top:'0%',
          // aspectScale:1,
          // // 地图区域的样式设置
          // itemStyle: {
          //     normal: {
          //         // borderColor: "rgba(0, 0, 0, 0.1)",
          //         borderWidth: 1,
          //     },
          //     // 鼠标放上去高亮的样式
          //     emphasis: {
          //         // areaColor: "rgba(0, 0, 0, 0.1)",
          //         borderWidth: 0,
          //     },
          // },
        },
        series: [
          {
            type: "effectScatter", //系列为涟漪类
            coordinateSystem: "geo",
            showEffectOn: "render", //涟漪特效的触发的方式 'render'直接显示  'emphasis'hover的时候显示
            symbolSize: 10, //标记的大小
            rippleEffect: {
              //涟漪的相关配置
              brushType: "fill", //涟漪的类型 stroke 线圈效果 stroke水波效果
              scale: 5, //波纹的最大的缩放比例
              period: 4, //涟漪的扩散周期
            },
            label: {
              //标签的配置
              
                formatter: "{b}",
                show: true,
                position: "bottom",
                color: "blue",
                offset: [0, 10], //文字偏移 [上，下]
              
            },
            itemStyle: {
              //每个点的配置
              
                shadowBlur: 5,
              
            },
            // data: [
            //   {
            //     name: "上海",
            //     value: [121.3359985, 31.1979007, 10], //经纬度与值
            //   },
            //   {
            //     name: "拉萨",
            //     value: [90.9169986, 29.29850075, 0],
            //   },
            // ],
          },
          {
            type: "lines",
            coordinateSystem: "geo",
          },
        ],
      };
      // 地图注册，第一个参数的名字必须和option.geo.map一致
      echarts.registerMap("china", shengfen);
      this.charts.setOption(option);
    },
    // initMap() {
    //   AMapLoader.load({
    //     key: "91114a2affd1f213cf06fac01809e538",
    //     version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    //     plugins: ["AMap.Scale"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    //   })
    //     .then((AMap) => {
    //       // 初始化地图
    //       this.map = new AMap.Map("container", {
    //         viewMode: "2D", //  是否为3D地图模式
    //         zoom: 13, // 初始化地图级别
    //         center: [113.65553, 34.748764], //中心点坐标  郑州
    //         resizeEnable: true,
    //       });
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },
    handCity(value) {
      const engineerCity = value[0];
      this.engineerCity = engineerCity;
      this.querySalesContactsList(engineerCity, value[1]);
    },
    handDealer(value) {
      this.queryDealerContactsList(value[0], value[1]);
    }
  },
  mounted() {
    this.getData();
    this.querySalesContactsList()

    // 锚点；
    const hash = this.$route.hash;
    console.log("----- location=", hash)
    if (hash) {
      const node = document.querySelector(hash);
      if (node) {
        node.scrollIntoView({behavior:"smooth"})
      }
    }
  },
};
</script>

<style lang="less" scoped>
:root {
  --screen-width: 800px;
}
.icon_image {
  height: 50px;
  width: 80px;
  margin-right: 10px;
  margin-bottom: 25px;

  object-fit:scale-down;
}
.contact {
  width: 100%;
  height: 100%;
  background: #fff;
  
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  row-gap: 82px;

  padding-bottom: 82px;

  .contact-body{
    width: 100%;
    
    padding: 0;
    
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    row-gap: 82px;
    
    @media screen and (max-width: 800px ) {
      min-width: 400px;
      padding: 0px 20px;
    }
  }

  .contact-box{
    width:100%;
    max-width: 1920px;
    padding: 0px 90px;

    @media screen and (max-width:960px) {
      padding: 0px;
    }
  }

  .contact-stripe{
    width:100%;
    padding: 0;
  }

}

.area {
  width: 100%;
  min-height: 600px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  -moz-column-gap: 98px;
  column-gap: 98px;
  row-gap: 40px;

  position: relative;

  .area-map-container{
    width: 100%;
    
    .area-map{
      width: 100%;
      height: 100%;
      min-height: 400px;
      box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.13);
      // border: 1px dashed;
    }
  }

  .area-list {
    height: 834px;
    width: 456px;
    min-width: 456px;
    background: #ffffff;
    box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.13);
    padding: 47px 24px;

    .area-list-inner {
      height: 100%;
      overflow: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .area-list-item {
      font-size: 21px;
      /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
      font-weight: 500;
      line-height: 36px;
      margin-bottom: 33px;

      p {
        margin: 0;
      }

      .subtitle {
        font-size: 16px;
        /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
        font-weight: 400;
        color: #282828;
        line-height: 36px;
        //margin-top: 11px;
      }
    }

    .item-active {
      // color: #00a0e9;
      font-size: 22px;
      /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
      font-weight: 500;
      color: #000000;
      line-height: 36px;
    }
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;

    .area-list {
      width: 100%;
      padding: 20px;
      margin-left: 0;
      min-width: unset;
    }
  }
}

.hall {
  height: 720px;
  background: #F0F0F0;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  row-gap: 20px;
  column-gap: 16px;

  @media screen and (max-width:960px) {
    flex-direction: column;
    height: 100%;
    max-height: 1080;
  }

  .hall-map{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 20px;
    
    a{
      width: 100%;
      height: 100%;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: fill;

      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }

.address {
    width: 600px;
    min-width: 600px;
    height: 720px;

    padding: 26px;

    .address-inner {
      height: 100%;
      
      overflow: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @media screen and (max-width:960px) {
      width:100%;
      min-width: 0px;
    }
}

  .hall-list-item {
    background: #FFFFFF;
    // box-shadow: -6px 0px 43px 0px rgba(0, 0, 0, 0.32);
    border-radius: 10px;
    margin-bottom: 24px;
    padding: 26px 32px;
    width: 100%;
    -webkit-transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
    transition: all 250ms cubic-bezier(.02, .01, .47, 1);


    p {
      font-size: 22px;
      /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
      font-weight: 500;
      color: #000000;
      line-height: 36px;
    }

    .subsmalltitle {
      font-size: 14px;
      /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
      font-weight: 400;
      color: #6F6F6F;
      line-height: 24px;
    }
  }

  .hall-list-item:hover {
    box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.32);
    transform: translate(0, -1px);
    transition-delay: 0s !important;
  }
}

.neck_banner {
  width: 100%;
  height: 500px;
  max-height: 500px;
  // background: url("../assets/contact/first.png") repeat center;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .neck_background{
    object-fit:cover;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}

.neck_text_eng {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;

  padding-top: 48px;

  z-index: 10;

  span {
    min-width: 1400px;
    font-size: 140px;
    /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
    font-weight: 800;
    color: #000000;
    opacity: 0.06;
    margin-bottom: 0px;
  }
}

.neck_text_chinese {

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 116px;

  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;


  .neck_text_icon_image{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: auto;
    flex-wrap: wrap;

    span {
      min-width: 200px;
      font-size: 80px;
      font-weight: 300;
      color: #000000;
    }
  }
}
.neck_text_description_chinese{
  width: 100%;

  height: auto;

  font-size: 22px;
  font-weight: 400;
  
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;
}

@media screen and (max-width:800px) {
  .neck_text_eng {
    font-size: 30px;
  }

  .neck_text_chinese {
    font-size: 40px;
  }

}

.chest {
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  @media screen and (max-width:960px) {
    padding: 20px;
  }
}

.chest_text1 {
  font-size: 40px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
  color: #000000;
  line-height: 40px;
}

// .chest_text1 {
//   font-size: 40px;
//   /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
//   font-weight: 400;
//   color: #000000;
//   line-height: 90px;
// }

.chest_text2 {
  font-size: 20px;
  margin-bottom: 42px;
  margin-top: 33px;
}


.belly {
  width: 100%;
  background: #f8f8f8;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: #F8F8F8;

  @media screen and (max-width:960px) {
    flex-direction: column;
  }
}

.belly-left-stripe{
  min-width: 400px;
  width:50%;
  background: #ececec;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: nowrap;

  @media screen and (max-width:960px) {
    width: 100%;
    min-width: 100px;
  }
}

.belly-left {
  width: 100%;
  max-width: 960px;
  height: 500px;
  
  position: relative;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;

  @media screen and (max-width:960px) {
    min-width: 400px;
      width: 100%;
  }
}

.belly-left-text-box {
  width: 320px;
  box-sizing: border-box;
  text-align: left;
  
  margin: 40px 40px 140px 140px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  z-index: 1000;

  position: absolute;
  left: 90px;
}

@media screen and (max-width:960px) {
  .belly-left-text-box {
    box-sizing: border-box;
    text-align: left;
    padding: 30px;
    max-width: 320px;
    height: 100%;

    margin: 20px 20px 100px 20px;
    left: 20px;
  }
}

.left_text1 {
  font-size: 40px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
  color: #010101;
  line-height: 60px;
}

.left_text2 {
  font-size: 20px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
  color: #000000;
  line-height: 30px;
  margin-top: 50px;
  margin-bottom: 20px;
}

.belly-img {
  width: 380px;
  height: 400px;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  align-content: center;
  flex-direction: row;

  position: absolute;
  right: 0;
  bottom: 0;

  img{
    width: 380px;
    height: 400px;
  }
}

.belly-right-stripe{
  
  width: 50%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;

  @media screen and (max-width:960px) {
    width: 100%;
    min-width: 100px;
  }
}

.belly-right {
  width: 100%;
  max-width: 960px;
  height: 500px;
  box-sizing: border-box;
  padding: 106px 70px 70px 70px;

  @media screen and (max-width:960px) {
    width:100%;
    min-width: 400px;
    padding: 90px 70px ;
  } 
}

.belly-right1 {
  font-size: 22px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
  color: #000000;
  line-height: 36px;
  margin: 58px 0 22px 0;

}

.belly-right2 {
  font-size: 16px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
  color: #000000;
  line-height: 28px;
  max-width: 520px;
}

.belly-right-engineer-out {
  height: 100%;
  .belly-right-engineer{
    margin-top: 110px;
  }
}


.sales-contact {
  height: auto;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  
  position: relative;

  .contact-box{
    padding: 70px 152px 0 152px;
    z-index: 1000;

    @media screen and (max-width:960px) {
      padding: 70px 40px 0 40px;
    } 
  }
}

.sales-contact-header-backgroud{
  width: 100%;
  height: 420px;
  
  background: #f5f5f5;

  position: absolute;
  top:0px;

  z-index: 900;
}
.sales-contact-header-text{
  width: 100%;
  
  padding: 0 ;

  text-align: center;
}

.sales-contact-title {
  font-size: 40px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
  color: #010101;

}

.sales-contact-desc {
  font-size: 20px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
  color: #000000;
  line-height: 30px;
  margin-bottom: 98px;
  margin-top: 43px;
  box-sizing: border-box;
}


.sales-contact-panel {
  margin: 0;
  padding: 20px;

  display: flex;
  justify-content: center;
  background-color: #FFFFFF;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  z-index: 1000;

  .contact-city{
    width:100%;
    padding: 30px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .sales-info-list{
    width: 100%;
    padding: 30px 90px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    .sales-info-box{
      max-width: 50%;
      min-width: 300px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;

      box-sizing: border-box;
    }

    @media screen and (max-width:960px) {
      padding: 30px 0px;
    } 
  }
}


.contact-city {
  margin-top: 0px !important;
  margin-top: 72px;
  display: flex;
}

/deep/ .contact-city  .el-select{
    width: 100% !important;
    max-width: 350px;
  }
.dealer{
  padding: 0px 50px;
}
.el-col {
  margin-bottom: 72px;
}


.light {
  color: #00A0E9;

  * {
    color: #00A0E9 !important;
  }
}

</style>