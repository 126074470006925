<template>
    <div class="city">
        <el-select clearable @change="handChange" v-model="provinceValue" class="selector" placeholder="请选择省份" size="large">
            <!-- <el-option v-for="item in city" :key="item.name" :label="item.name" :value="item.name" /> -->
            <el-option v-for="item in province" :key="item.province" :label="item.province" :value="item.province" />
        </el-select>
        <el-select clearable @change="handChangeCity" v-model="cityValue" class="selector" placeholder="请选择城市" size="large"
            style="margin-left: 33px">
            <!-- <el-option v-for="item in city.filter(item=>item.name==provinceValue)[0]?.children" :key="item.name"
                :label="item.name" :value="item.name" /> -->
            <el-option v-for="item in city[0]?.cities" :key="item"
                :label="item" :value="item" />
                
        </el-select>
    </div>
</template>
  
<script setup>
import { ref, defineEmits,onMounted } from 'vue';
import request from "@/utils/request";
//import city from "@/assets/city.js";
const provinceValue = ref("");
const cityValue = ref("");
const province = ref('');
const city = ref('');
const handChange = () => {
    cityValue.value = "";
    city.value = province.value.filter(item => item.province === provinceValue.value);
}
const emit = defineEmits(["onChange"])
const handChangeCity = () => {
    emit('onChange', [provinceValue.value, cityValue.value])
}
onMounted(async () => {
  // 获取品牌logo
  request({
    url: "/api/ui/getdealerarea",
    method: "get",
  })
    .then((res) => {
      if (res.code == 0) {
        province.value = res.data;
      }
    })
    .catch((e) => {
      console.log(e);
    });
});
</script>
<style scoped>
.city {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 38px;
    row-gap: 16px;

}

.city .el-select{
    margin-left: 0 !important;
}
</style>