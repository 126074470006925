<template>
  <div class="brand">
    <div class="fs-flex fs-flex-center fs-flex-align-center">
      <img :src="`/api/ffs/file/${fileId}`" />
    </div>
  </div>
</template>
  
<script setup>
import { ref, defineProps, onMounted } from "vue";
import request from "@/utils/request";
const fileId = ref("");
const props = defineProps({
  id: String,
});
onMounted(async () => {
  // 获取品牌logo
  console.log(123, props.id);
  request({
    url: "/api/ui/productBrand/" + props.id,
    method: "get",
    // params: {
    //   name: this.input,
    //   productBrandId: this.brand,
    //   productTypeId: this.category,
    //   page:1,
    //   size:100
    // },
  })
    .then((res) => {
      if (res.code == 0) {
        // 产品中心反参为数组，暂取第一个对象内容渲染页面，接口待明确
        // let info = res.data[0]
        // console.log(res.data);
        fileId.value = res.data.colorIcon.id;
      }
    })
    .catch((e) => {
      console.log(e);
    });
});
</script>
<style lang="less" scoped>
.brand {
  display: inline-block;
  width: 111px;
  height: 61px;
  border: 1px solid #d7d7d7;
  margin: 10px 20px 10px 0px;
  box-sizing: unset;
  & > div {
    width: 100%;
    height: 100%;
    img {
      width: 80%;
    }
  }
}
</style>