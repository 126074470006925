<template>
    <div class="dealer">
        <div class="title">{{props.data.companyName}}</div>
        <div class="subtitle">地址:{{props.data.address}}</div>
        <div class="subtitle">授权品牌：
        </div>
        <div class="fs-flex brands"> 
            <Brand v-for="(item,index) in props.data.brands" :key="index" :id="item" />
        </div>
    </div>
</template>
  
<script setup>
import Brand from './Brand'
import { defineProps, onMounted } from 'vue'
const props = defineProps({
    data: Object,
});
onMounted(async () => {
    // 获取品牌logo
    console.log(123, props.data);

})
</script>
<style lang="less" scoped>
.dealer {
    text-align: left;
    

    .title {
        font-size: 22px;
        /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
        font-weight: 400;
        color: #000000;
        line-height: 36px;
        margin-bottom: 35px;

        word-wrap: break-word;
    }

    .subtitle {
        width: 100%;

        font-size: 16px;
        /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
        font-weight: 400;
        color: #000000;
        line-height: 28px;

        word-wrap: break-word;
    }

    .brands{
        flex-wrap: wrap;
    }
}

</style>